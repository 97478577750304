import { createStore } from 'vuex'
const en = require('../languages/en.json')
const ru = require('../languages/ru.json')

export default createStore({
	state: {
		size:{width:0,height:0},
		server:'https://kasatkin.io/',
		lang: 'en',
		internalization:{en, ru}
	},
	getters: {
		toLocal:(state)=>(key)=>state.internalization[state.lang][key],
	},
	mutations: {
		changeLang(state,l) {
			state.lang = l;
			localStorage.setItem('lang',l)
			if(document&&document.documentElement) document.documentElement.lang=l;
		},
		resizeBody(state){
            state.size.width=window.innerWidth;
            state.size.height=window.innerHeight;
            window.addEventListener('resize', ()=>{
                state.size.width=window.innerWidth;
                state.size.height=window.innerHeight;
            });
        },
	},
	actions: {
	},
	modules: {
	}
})
