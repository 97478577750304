<template>
    <n-grid cols="2 m:5" responsive="screen" :class="$style.menu">
        <n-gi :class="$style.logo">
            <router-link to="/" class="a-not-href">
                <!-- <img :src="state.server+'img/logo.svg'"> -->
                <div :class="$style.logoText">
                    <span>KASATKIN </span>
                    <span>PAY</span>
                </div>
            </router-link>
        </n-gi>
        <n-gi span="0 m:3" :class="['text-center',$style.aMenu]">
            <!-- <n-menu value="activeKey" :render-label="renderLabel" mode="horizontal" :options="menuOptions" /> -->
            <div :class="[{[$style.footerMenu]:!header},$style.menuRow]">
                <div :class="$style.menuItem" @mousemove="shows[0]=true" @mouseleave="shows[0]=false">
                    <router-link class="a-not-href up-text" to="/">{{getters.toLocal('about')}}</router-link>
                    <Transition>
                        <about-vue v-if="shows[0]"/>
                    </Transition>
                </div>
                <!-- <div :class="$style.menuItem" @mousemove="shows[1]=true" @mouseleave="shows[1]=false">
                    <router-link class="a-not-href up-text" to="/lab">{{getters.toLocal('development')}}</router-link> -->
                    <!-- <Transition> -->
                        <!-- <dev-vue v-if="shows[1]"/> -->
                    <!-- </Transition> -->
                <!-- </div> -->
                <!-- <div :class="$style.menuItem" @mousemove="shows[2]=true" @mouseleave="shows[2]=false">
                    <a href="https://kasatkin-foundation.gitbook.io/kasatkin-pay/" class="a-not-href" target="_blank">PAY</a>
                    <Transition>
                        <pay-vue v-if="shows[2]"/>
                    </Transition>
                </div> -->
                <router-link to="/career" :class="$style.menuItem">{{getters.toLocal('career')}}</router-link>
            </div>
        </n-gi>
        <n-gi>
            <div v-if="header" :class="$style.mHead">
                <n-dropdown v-model:show="langActiveM" :class="[$style.langChange,$style.langChangeM]" trigger="click" :options="optionsLangs" @select="langSelect">
                    <a :class="[$style.langChangeHref,$style.langChange,'nowrap',{[$style.langActive]:langActiveM}]">{{state.lang.toUpperCase()}}<arrowIcon/></a>
                </n-dropdown>
                <div :class="[$style.menuBtn,{[$style.active]:show}]" @click="toShow(false)" v-if="header"><div v-for="i in 3" :key="i"></div></div>
                <Transition>
                    <div :class="$style.menuContainer" v-show="show">
                        <div :class="$style.menuList">
                            <about-vue/>
                            <dev-vue/>
                            <pay-vue/>
                        </div>
                        <div :class="[$style.icons,$style.mdFlex]">
                            <a v-for="c,i in icons" :key="i" :target="c.href.includes('http')?'_blank':''" :href="c.href" class="a-not-href">
                                <n-icon :class="$style.aIcon" :component="c.icon" :size="20"/>
                            </a>
                        </div>
                    </div>
                </Transition>
            </div>
            <div :class="['d-flex',$style.icons,$style.descktop,{[$style.headerIc]:header}]">
                <n-dropdown v-model:show="langActive" v-if="header" :class="$style.langChange" trigger="click" :options="optionsLangs" @select="langSelect">
                    <a :class="[$style.langChangeHref,$style.langChange,'nowrap',{[$style.langActive]:langActive}]">{{state.lang.toUpperCase()}}<arrowIcon/></a>
                </n-dropdown>
                <a v-for="c,i in icons" :key="i" :target="c.href.includes('http')?'_blank':''" :href="c.href" class="a-not-href">
                    <n-icon :class="$style.aIcon" :component="c.icon" :size="20"/>
                </a>
            </div>
        </n-gi>
    </n-grid>
</template>
<script setup>
import {ref,shallowReactive,h,watch,useCssModule} from 'vue';
import {RouterLink,useRoute} from 'vue-router';
import arrowDrop from './icons/arrowDrop.vue';
import {NGrid,NGi,NIcon,NDropdown} from 'naive-ui';
import {useStore} from 'vuex';
import arrowIcon from '@/components/icons/arrowBt.vue'

const {state,getters,commit} = useStore();
const props = defineProps({header:{type:Boolean,default:false}})
const $style = useCssModule()
const show = ref(false);
const route = useRoute()
const icons = shallowReactive([]);
const langActive = ref(false)
const langActiveM = ref(false)
const optionsLangs = Object.keys(state.internalization).map(k=>({label:k.toUpperCase(),key:k}));
(async ()=>{icons.push(...await Promise.all([
    {icon:'linkedin',href:'https://www.linkedin.com/company/kasatkin-pay/'},
    {icon:'telegram',href:'https://t.me/kasatkinpay'},
    {icon:'twitter',href:'https://twitter.com/KasatkinPa76864'},
    {icon:'email',href:'mailto:welcome@kasatkin.io'}
].map(async i=>{return {href:i.href,icon:(await import(`@/components/icons/${i.icon}.vue`)).default}})))})();

const langSelect = e=>{
    commit('changeLang',e)
}
const about = [
    {text:'ecosystem_nav',link:'/#about'},
    {text:'what_is_pay',link:'/#whatPay'},
    {text:'functions',link:'/#functions'},
    {text:'api',link:'/#api'},
    {text:'contacts',link:'#contacts'},
    {text:'coin',link:'#',com:true},
    // {text:'exchange',link:'#',com:true},
    // {text:'crypto_core',link:'/#',com:true},
    {text:'career',link:'/career',class:['d-none','d-m-block']},
]
const devs = [
    {text:'about',link:'/lab/#about__company'},
    {text:'contact_us',link:'/lab/#contacts_us'},
    {text:'services',link:'/lab/#our_servise'},
    {text:'opinions',link:'/lab/#opinion'},
    {text:'portfolio',link:'/lab/#portfolio'},
    {text:'stages',link:'/lab/#etaps'},
    {text:'reasons_why',link:'/lab/#reasons__why'},
    {text:'presentation',link:'https://drive.google.com/file/d/1iMZ1DrG7uhEI_MljAt0vy_7_GAeIVope/view'}
]
const shows = ref([false,false,false])
const toShow=(f)=>{
    show.value=f?false:!show.value;
    document.documentElement.style.overflow=document.body.style.overflow=show.value?'hidden':'';
}
const aboutVue = {
    setup(){
        return ()=>h('div',{class:$style.mDropdown},h('div',null,[
            h(RouterLink,{to:'/',class:$style.dTitle,onClick:()=>toShow(true)},{default:()=>[getters.toLocal('main').toUpperCase() ,h(arrowDrop,{class:$style.devIcon})]}),
            about.map((a,i)=>h(RouterLink,{key:i,class:[$style.aboutListItem,'cl-white'].concat(a.class||[]),to:a.link||'/',onClick:()=>toShow(false)},{default:()=>[
                h('span',null,getters.toLocal(a.text)),
                h(arrowDrop),
                a.com?h('span',{class:$style.coming},getters.toLocal('coming_soon')):undefined
            ]}))
        ]));
    }
}
// const devVue = {
//     setup(){
//         const getLink = (d)=>{
//             if(d.link.includes('http')) return h('a',{href:d.link,target:'_blank',class:$style.drDev,onClick:()=>toShow(false)},getters.toLocal(d.text));
//             return h(RouterLink,{to:d.link,class:$style.drDev,onClick:()=>toShow(false)},{default:()=>getters.toLocal(d.text)});
//         }
//         return ()=>h('div',{class:$style.mDropdown,style:'max-width: 385px;'},h('div',null,[
//             h(RouterLink,{to:'/lab',class:[$style.dTitle,'up-text'],onClick:()=>toShow(false)},{default:()=>[getters.toLocal('development'),h(arrowDrop,{class:$style.devIcon})]}),
//             h('div',{class:$style.devFlex},devs.map(d=>getLink(d))),
//             h('div',{class:$style.devBt},[h('p',null,getters.toLocal('lern_about')),h(RouterLink,{class:$style.visitBtn,to:'/lab',onClick:()=>toShow(false)},{default:()=>getters.toLocal('visit_page')})])
//         ]));
//     }
// }
const payVue = {
    setup(){
        //const ls = [{to:'/#pay',text:'crypto_pay'},{to:'/#customizable',text:'benefits_pay_system'},{to:'/#whatPay',text:'what_pay'}];
        return ()=>h('div',{class:$style.mDropdown,style:'width: 385px;min-width:350px'},h('div',null,[
            h(RouterLink,{class:[$style.dTitle,'up-text'],to:'/career'},{default:()=>[getters.toLocal('career'),h(arrowDrop,{class:$style.devIcon})]}),
            //...ls.map(l=>h(RouterLink,{to:l.to,class:[$style.aboutListItem,'cl-white'],onClick:()=>toShow(false)},{default:()=>[h('span',null,getters.toLocal(l.text)),h(arrowDrop)]})),
            h('div',{class:$style.devBt,style:'margin-top: 25px;'},h('div',{class:$style.payFlex},[
                h('div',null,[
                    h('p',{class:$style.vis},getters.toLocal('join_team')),
                    h(RouterLink,{to:'/career',onClick:()=>toShow(false),class:$style.visitBtn},{default:()=>getters.toLocal('visit')})
                ]),
                h('img',{src:state.server+'img/coins.svg'})
            ]))
        ]));
    }
}
watch(()=>route.fullPath,()=>{
    toShow(true)
})
</script>
<style scoped>
.v-enter-active,.v-leave-active {transition: opacity 0.4s ease;}
.v-enter-from,.v-leave-to {opacity: 0;}
</style>
<style module lang="scss">
.langChangeHref{
    display: flex;
    height: 24px;
    align-items: center;
    transition: color .2s ease-in-out;
    margin-right: 50px!important;
    user-select: none;
    cursor: pointer;
    &:hover{color:#bd69a9}
    svg{
        width: 24px;
        height: 24px;
        margin-top: -3px;
        margin-left: -3px;
        transform: rotate(180deg);
        transition: transform .2s ease-in-out;
    }
    &.langActive svg{transform: rotate(0deg);}
}
.langChange{
    font-family: 'Good Times';
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-decoration: none;
}
.mHead{.langChangeHref{display: none;}}
.langChangeM{display: none;}
.logo{z-index: 100;}
.mdFlex{display: none;}
.menu .menuContainer{
    position: fixed;
    background-color: #0E0E0E;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    z-index: 99;
    .icons{
        margin: 30px 0 0 0;
        justify-content: center;
        height: auto;
        >a{
            margin: 0 15px;
            .aIcon{vertical-align: bottom;}
        }
    }
    .menuList{
        display: flex;
        white-space: nowrap;    
        overflow-x: auto;
        overflow-y: hidden;
        flex-flow: nowrap;
        scroll-snap-type: x mandatory;
        position: relative;
        padding: 0 12px;
        margin-top: 150px;
        &::-webkit-scrollbar {-webkit-appearance: none;width: 0;height: 0;display: none;}
        .mDropdown{
            scroll-snap-align: center;
            position: relative;
            max-width: 100%;
            height: auto;
            left: auto;
            transform: none;
            margin-left: 12px;
            margin-bottom: 40px;
            > div{height: 100%;}
            &:first-child{margin-left: 0;}
            &::before{display: none;}
            .devBt p{white-space: break-spaces;}
        }
    }
}
.menuBtn{
    z-index: 100;
    height: 16px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 0;
    display: none;
}
.menuBtn div{
    position: absolute;
    left: 0px;
    top: 2px;
    height: 0.04em;
    width: 100%;
    background-color: #fff;
    transition-duration: .2s;
}
.menuBtn div+div{
    top: 50%;
    transform: translateY(-50%);
}
.menuBtn div+div:last-child {
    bottom: 1px;
    top: unset;
}
.active.menuBtn div:first-child{
    top: 4px;
    transform: translateY(4px) rotate(45deg);
}
.active.menuBtn div+div:not(:last-child){display: none;}
.active.menuBtn div+div:last-child {
    bottom: 1px;
    transform: translateY(-6px) rotate(-45deg);
}
.menuRow{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}
.menu{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    .devIcon{margin-left: 6px;}
    .devFlex{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 12px;
        .drDev{
            color: #B6B6B6;
            text-decoration: none;
            width: 50%;
            flex: 1 0 50%;
            margin-bottom: 2px;
            &:hover{text-decoration: underline;}
        }
    }
    .visitBtn{
        display: inline-block;
        text-decoration: none;
        background-color: #fff;
        color: #000;
        border: none;
        height: 30px;
        line-height: 30px;
        border-radius: 30px;
        padding: 0 18px;
        font-weight:700;
        &:hover,&:active,&:focus{
            background-color: #fff;
            color: #000;
            border: none;
            opacity: .8;
            box-shadow: none;
        }
    }
    .devBt{
        color: #A9A9A9;
        background-color: #282828;
        border-radius: 15px;
        margin: 10px -10px 0 -10px;
        padding: 20px;
        p{margin-top: 0;&.vis{color: #fff;font-size: 14px;font-weight: 700;}}
    }
    .payFlex{
        display: flex;
        margin: -20px 0;
        position: relative;
        >div{padding: 15px 0;margin-bottom: 15px;.visitBtn{margin-top: 15px;}}
        p{margin-bottom: 23px;}
        img{
            max-width: 120px;
            position: absolute;
            right: -20px;
            top: 10px;
        }
    }
    .mDropdown{
        z-index: 999;
        font-family: Inter;
        position: absolute;
        max-width: 325px;
        width: max-content;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -6px;
        text-align: left;
        padding-top: 20px;
        > div{
            background-color: #181818;
            padding: 15px 25px;
            border-radius: 20px;
        }
        &::before{
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
            transform: translateX(-50%) rotate(137deg);
            border-radius: 0 0 0 0.25em;
            top:15px;
            left: 50%;
            background-color: #181818;
        }
    }
    .footerMenu .mDropdown{
        top: 9px;
        transform: translate(-50%,-100%);
        padding-bottom: 20px;
        &::before{
            bottom: 13px;
            top: auto;
            transform: translateX(-50%) rotate(316deg);
        }
    }
    .dTitle{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        text-decoration: none;
        color: #fff;
    }
    .aboutListItem{
        font-size: 14px;
        display: block;
        margin: 10px 0;
        text-decoration: none;
        span{
            margin-right: 6px;
            transition: all ease-in-out .3s;
            &:first-child:hover{text-decoration: underline;}
        }
        .coming{
            color: #6D6D6D;
            font-size: 13px;
            margin: 0 0 0 6px;
        }
    }
}
.aMenu {
    position: relative;
    z-index: 30;
    .menuItem{
        font-family: 'Good Times';
        font-weight: 400;
        font-size: 15px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        position: relative;
        transition: color .2s ease-in-out;
        &:hover{color:#bd69a9}
        &:not(:last-child){margin-right: 50px;}
    }
}
.icons{
    height: 100%;
    align-items: center;
    justify-content: end;
    position: relative;
    z-index: 30;
}
.logo{
    .logoText{
        color:#EA00FF;
        display: inline-block;
        margin-left: 5px;
        vertical-align: super;
        font-size: 24px;
        font-family: 'Teko', sans-serif;
    }
    span{
        &:first-child{color: #FFFFFF;}
        font-weight: bold;
        line-height: 1;
    }
}
.icons >*:not(:last-child){margin-right: 20px;}
@media(max-width:1920px) {
    .aMenu .menuItem,.langChange{font-size: 14px;}
}
@media(max-width:1230px) {
    .langChangeHref{margin-right: 20px!important;}
}
@media(max-width:1100px) {
    .aMenu .menuItem:not(:last-child){margin-right: 20px;}
}
@media(max-width:950px) {
    .aMenu .menuItem:not(:last-child){margin-right: 10px;}
    .icons.descktop {.aIcon{font-size: 17px!important;}>*:not(:last-child){margin-right: 12px;}}
}
@media(max-width:830px) {
    .aMenu .menuItem:not(:last-child){margin-right: 8px;}
    .icons.descktop {.aIcon{font-size: 17px!important;}>*:not(:last-child){margin-right: 10px;}}
}
@media(max-width:768px) {
    .menu .icons.headerIc{&.descktop{display: none;}}
    .menu .menuBtn{display: block;}
    .menu .menuContainer .menuList .mDropdown{flex: 1 0 70%;max-width: 70%!important;min-width: auto !important;}
    .menu .menuContainer{display: block;}
    .menu .mdFlex{display: flex;}
    .langChange,.langChangeHref{display: none;}
    .langChangeM{display: block;}
    .mHead{justify-content: flex-end;display: flex;.langChangeHref{margin-top: -5px;width:43px;justify-content: flex-end;margin-right: 40px!important;position: relative;z-index: 100;display: flex;}}
}
@media(max-width:576px) {
    .menu .menuContainer .menuList .mDropdown{flex: 1 0 90%;max-width: 90%!important;}
    .menuBtn{top: 5px;}
}
@media(max-width:360px) {
    .icons >*:not(:last-child){margin-right: 10px;}
}
@media(max-width:350px) {
    .icons:not(.headerIc) >*:not(:last-child){margin-right: 6px;}
}
</style>